import { render, staticRenderFns } from "./heritage_use_function.vue?vue&type=template&id=226eb371&scoped=true&"
import script from "./heritage_use_function.vue?vue&type=script&lang=js&"
export * from "./heritage_use_function.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226eb371",
  null
  
)

export default component.exports